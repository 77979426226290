import request from "@/utils/request.js";

export const login = (params) => request("post", "/gov/login", params); // 登录    getUserRole

export const getVerifyImage = () => request("get", "/gov/getVerifyImage"); // 获取验证码图片

export const logout = () => request("post", "/gov/logout", "", true); // 登出

export const getBannerList = () => request("post", "/gov/getBannerList", ""); // 获取当前的banner状态

export const saveBannerList = (params) =>
  request("post", "/gov/saveBannerList", params, false); // 保存Banner信息

export const getNewsTitles = (params) =>
  request("post", "/gov/getNewsTitles", params); // 获取新闻标题列表

export const getNewsList = (params) =>
  request("post", "/gov/getNewsList", params); // 获取新闻列表

export const getContactList = (params) =>
  request("post", "/gov/getContactList", params); // 获取联系人列表

export const readContact = (params) =>
  request("post", "/gov/readContact", params); // 更新已读

export const deleteNews = (params) =>
  request("post", "/gov/deleteNews", params); // 删除新闻

export const getNewsDetail = (params) =>
  request("post", "/gov/getNewsDetail", params); // 获取新闻详情

export const saveOneNews = (params) =>
  request("post", "/gov/saveOneNews", params); // 保存新闻

export const changePassword = (params) =>
  request("post", "/gov/changePassword ", params); // 获取新闻详情//新增修改新闻

export const getProjectList = (params) =>
  request("post", "/gov/getProjectList", params); // 获取项目列表

export const getProjectDetail = (params) =>
  request("post", "/gov/getProjectDetail", params); // 获取项目详情

export const updateProjectReaded = (params) =>
  request("post", "/gov/updateProjectReaded", params); // 更新项目为已读

export const updateAllProjectReaded = (params) =>
  request("post", "/gov/updateAllProjectReaded", params); // 全部更新为已读

export const getCardList = (params) =>
  request("post", "/gov/getCardList ", params); // 获取名片列表

export const getCardDetail = (params) =>
  request("post", "/gov/getCardDetail  ", params); // 获取名片详情

export const programInfo = () => request("post", "/gov/getProgramInfo"); // 获取名片详情

export const getBannerSize = () => request("post", "/gov/getBannerSize"); // 获取轮播图尺寸

export const getPagesUrl = () => request("post", "gov/getPagesUrl"); // 设置挂载数

export const getCommonPagesUrl = () => request("post", "gov/getCommonPagesUrl"); // 打包程序

export const getActivitiesList = (params) =>
  request("post", "/gov/getActivitiesList  ", params); // 获取活动列表

export const downActivityImg = (params) =>
  request("post", "/gov/getWxaCodeUnLimited", params); // 获取活动签到太阳码

export const getActivityDetail = (params) =>
  request("post", "/gov/getActivityDetail", params); // 获取活动详情

export const getSignUpActivitiesList = (params) =>
  request("post", "/gov/getSignUpActivitiesList  ", params); // 获取活动报名列表

export const saveOneActivity = (params) =>
  request("post", "/gov/saveOneActivity  ", params); // 新增一条活动

export const deleteActivity = (params) =>
  request("post", "/gov/deleteActivity  ", params); // 删除一条活动

export const setActivityTop = (params) =>
  request("post", "/gov/setActivityTop", params); // 置顶活动

export const setNewsTop = (params) =>
  request("post", "/gov/setNewsTop", params); // 置顶新闻

export const getCarriersList = (params) =>
  request("post", "/gov/getCarriersList  ", params); // 获取载体列表

export const setCarrierTop = (params) =>
  request("post", "/gov/setCarrierTop", params); // 置顶载体

export const deleteCarrier = (params) =>
  request("post", "/gov/deleteCarrier  ", params); // 删除一条载体

export const getCarrierDetail = (params) =>
  request("post", "/gov/getCarrierDetail", params); // 获取载体详情

export const saveOneCarrier = (params) =>
  request("post", "/gov/saveOneCarrier", params); // 获取载体详情

export const getApplyCarriersList = (params) =>
  request("post", "/gov/getApplyCarriersList  ", params); // 获取申请载体列表

export const getDemandDockingList = (params) =>
  request("post", "/gov/getDemandDockingList  ", params); // 获取需求列表

export const getApplyMemberList = (params) =>
  request("post", "/gov/getApplyMemberList  ", params); // 获取入会申请表

export const getArticles = (params) =>
  request("post", "/gov/getArticlesToRichText", params); // 获取公众号文章内容

export const getApplyCardsList = (params) =>
  request("post", "/gov/getApplyCardsList", params); // 获取名片功能申请列表

export const updateApplyCards = (params) =>
  request("post", "/gov/updateApplyCards", params); // 更新名片功能申请列表
