/*
 * @Descripttion:
 * @version:
 * @Author: 张志浩
 * @Date: 2020-08-18 13:45:19
 * @LastEditors: 张志浩
 * @LastEditTime: 2021-09-11 16:07:53
 */
/**
 * @Author: pikaz
 * @Date:   2019-04-01T14:31:35+08:00
 * @Last modified by:   pikaz
 * @Last modified time: 2019-04-01T15:14:01+08:00
 */

// 判断是否是生产环境

const test = "/apis";
const product = "https://api.joinzs.com/mpgenerator-1.0.1";
// const test = 'http://10.42.0.1:12000/mpgenerator'
// webpack在开发环境和生产环境分别执行不同的js文件，process.env.NODE_ENV设置了不同的值，process.env.NODE_ENV在生产环境中值为'production'(这个值是在build/build.js中第4行设置的)
var isPro = process.env.NODE_ENV === "production";
// 如果是生产环境 我们就使用服务器的uri，如果是开发环境，我们就添加/apis前缀
export const SERVERURL = isPro ? product : test;
export const CONFIG = SERVERURL + "/gov/uploadFile/";
// export const RICHURL = product + 'upload/uploadImage4RichText/'
