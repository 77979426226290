// import { login, logout, getInfo } from '@/api/user'
import { login, logout, getContactList, programInfo } from "@/apis/user";
import { getCookie, removeCookie, setCookie } from "@/utils/auth";
import router from "@/router";
import { constantRoutes } from "@/router";
const state = {
  auth: getCookie("Auth"),
  unReadCount: "",
  programInfo: "",
};
const mutations = {
  SET_TOKEN: (state, auth) => {
    state.auth = auth;
  },
  SET_UNREADCOUNT: (state, num) => {
    state.unReadCount = num;
  },
  SET_PROGRAMINFO: (state, programInfo) => {
    state.programInfo = programInfo;
  },
};
const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password, verity, key } = userInfo;
    return new Promise((resolve, reject) => {
      login({
        username: username.trim(),
        password,
        verifyCode: verity,
        verifyKey: key,
      })
        .then((response) => {
          // //console.log(response)
          const res = response.result;

          commit("SET_TOKEN", res.auth);
          // commit('FIRST_LOGIN', res.firstLogin)
          setCookie("Auth", res.auth);
          // ('firstLogin', res.firstLogin)
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // get user info
  programInfo({ commit }) {
    return new Promise((resolve, reject) => {
      programInfo()
        .then((response) => {
          const res = response.result;
          commit("SET_PROGRAMINFO", res);
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // remove token
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit("SET_TOKEN", "");
      commit("SET_PROGRAMINFO", "");
      commit("SET_HREFLIST", "");
      removeCookie("Auth");
      router.options.routes = constantRoutes;
      resolve();
    });
  },
  logout() {
    return new Promise((resolve, reject) => {
      logout()
        .then((response) => {
          // removeCookie('Auth')
          // commit('SET_PROGRAMINFO', '')
          // this.$store.dispatch('resetToken')
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getContactList({ commit }, data = {}) {
    return new Promise((resolve, reject) => {
      getContactList(data)
        .then((response) => {
          var res = response.result;
          commit("SET_UNREADCOUNT", res.unReadCount);
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
export default {
  state,
  mutations,
  actions,
  // getters,
  // mutations,
};
