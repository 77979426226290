/**
 * @Author: pikaz
 * @Date:   2019-04-01T14:31:53+08:00
 * @Last modified by:   pikaz
 * @Last modified time: 2019-04-01T17:42:10+08:00
 */
import axios from "axios";
import { SERVERURL } from "./config";
import { getCookie, removeCookie } from "./auth";
import { Message, Loading } from "element-ui";
import { VueAxios } from "./axios";

import store from "../store";
let loading; // 定义loading变量
function startLoading() {
  // 使用Element loading-start 方法
  loading = Loading.service({
    lock: true,
    text: "loading",
    // background: 'rgba(0, 0, 0, 0.5)',
    // target: document.querySelector('.loading-area')// 设置加载动画区域
  });
}
function endLoading() {
  // 使用Element loading-close 方法
  loading.close();
}

// 那么 showFullScreenLoading() tryHideFullScreenLoading() 要干的事儿就是将同一时刻的请求合并。
// 声明一个变量 needLoadingRequestCount，每次调用showFullScreenLoading方法 needLoadingRequestCount + 1。
// 调用tryHideFullScreenLoading()方法，needLoadingRequestCount - 1。needLoadingRequestCount为 0 时，结束 loading。
let needLoadingRequestCount = 0;
export function showFullScreenLoading() {
  if (needLoadingRequestCount === 0) {
    startLoading();
  }
  needLoadingRequestCount++;
}

export function tryHideFullScreenLoading() {
  if (needLoadingRequestCount <= 0) return;
  needLoadingRequestCount--;
  if (needLoadingRequestCount === 0) {
    endLoading();
  }
}

const request = axios.create({
  // API 请求的默认前缀
  baseURL: SERVERURL,
  timeout: 60000, // 请求超时时间
});

// 请求拦截 这里做token判断
request.interceptors.request.use(
  (response) => {
    if (getCookie("Auth")) {
      // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
      response.headers.post["auth"] = getCookie("Auth");
    }
    response.headers.post["Content-Type"] = "application/json";
    if (response.isLoading) {
      showFullScreenLoading();
    }

    return response;
  },
  (error) => {
    tryHideFullScreenLoading();
    // console.log(error)
    return Promise.reject(error);
  }
);
// 相应拦截 这里做接口状态处理
request.interceptors.response.use(
  (response) => {
    const res = response.data;
    tryHideFullScreenLoading();
    if (res.code !== 0) {
      if (store.state.sort.sortout) return;
      if (res.code === 601) {
        removeCookie("Auth");
        setTimeout(() => {
          window.location.href = "/";
          return;
        }, 1000);
        store.commit("GET_SORTOUT", true);
      }

      Message({
        message: res.msg || "error",
        type: "error",
      });
      return Promise.reject(res || "error");
    } else {
      // //console.log(response)
      return res;
    }
  },
  (error) => {
    tryHideFullScreenLoading();

    if (store.state.sort.sortable) return;
    Message({
      message: "服务器超时,请稍后再试",
      type: "error",
    });
    store.commit("GET_SORT", true);
    // this.$message1('服务器超时,请稍后再试', 'error')
    return Promise.reject(error);
  }
);

export default function (method, url, data = null, isLoading) {
  method = method.toLowerCase();
  if (method === "post") {
    return request.post(url, data, { isLoading: isLoading });
  } else if (method === "get") {
    return request.get(url, { params: data }, { isLoading: isLoading });
  }
}

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, request);
  },
};

export { installer as VueAxios };
