// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App";
import router from "./router";
import store from "./store";
// import ElementUI from 'element-ui'
import "./permission.js"; // permission control

import "@/icons"; // icon

import "normalize.css/normalize.css"; // A modern alternative to CSS resets
import "@/styles/index.scss"; // global css

import "tinymce/tinymce";
import "tinymce/themes/silver";

/* eslint-disable no-new */
Vue.config.productionTip = false;

import VueCropper from "vue-cropper";
Vue.use(VueCropper);

import { VueAxios } from "./utils/request";
Vue.use(VueAxios);

// Vue.use(ElementUI)
import {
  Menu,
  MenuItem,
  Breadcrumb,
  BreadcrumbItem,
  Scrollbar,
  Submenu,
  Table,
  TableColumn,
  Progress,
  Pagination,
  Upload,
  Button,
  Input,
  Image,
  Tooltip,
  Form,
  FormItem,
  Checkbox,
  Dialog,
  Row,
  Col,
  Switch,
  Select,
  Option,
  Message,
  Loading,
  Icon,
  MessageBox,
  DatePicker,
  Popconfirm,
} from "element-ui";
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Submenu);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Scrollbar);
Vue.use(Table);
Vue.use(Image);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Button);
Vue.use(Input);
Vue.use(Tooltip);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Checkbox);
Vue.use(Dialog);
Vue.use(Row);
Vue.use(Col);
Vue.use(Upload);
Vue.use(Switch);
Vue.use(Select);
Vue.use(Option);
Vue.use(Loading);
Vue.use(Icon);
Vue.use(Progress);
Vue.use(DatePicker);
Vue.use(Popconfirm);
Vue.component(Message.name, Message);
Vue.prototype.$message = Message;

Vue.prototype.$message1 = function message(message, type, duration = 2000) {
  this.$message({
    message,
    center: true,
    duration,
    type,
  });
};
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$alertMsgBox = function alert(
  msg = "确认要删除该数据？",
  title = "提示",
  settings = {}
) {
  Object.assign(settings, {
    // 合并对象
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    dangerouslyUseHTMLString: true, // 允许确认框内容为html格式
  });
  return MessageBox.confirm(
    `<p style="font-weight:bold;">${msg}</p>`,
    title,
    settings
  );
};
Vue.prototype.$handleConfirm = function handleCofirm(
  text = "确定执行此操作吗？",
  type = "info"
) {
  return MessageBox.confirm(text, "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    roundButton: true,
    type: type,
    center: false,
  });
};

// 弹出框禁止滑动
Vue.prototype.noScroll = function () {
  var mo = function (e) {
    e.preventDefault();
  };
  document.querySelector(".app-main").style.overflow = "hidden";
  document.querySelector(".app-main").addEventListener("touchmove", mo, false); // 禁止页面滑动
};

// 弹出框可以滑动
Vue.prototype.canScroll = function () {
  var mo = function (e) {
    e.preventDefault();
  };
  document.querySelector(".app-main").style.overflow = ""; // 出现滚动条
  document
    .querySelector(".app-main")
    .removeEventListener("touchmove", mo, false);
};
if (!!window.ActiveXObject || "ActiveXObject" in window) {
  alert(
    "非常抱歉,旧版浏览器无法运行新标准的网站\n" +
      "请使用最新版的浏览器重新访问该地址\n" +
      "建议使用:Chrome,Firefox,Safari等主流浏览器"
  );
  window.opener = null;
  window.open("", "_self");
  window.close();
}

new Vue({
  el: "#app",
  router,
  store,
  components: { App },
  template: "<App/>",
});
