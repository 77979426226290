/*
 * @Descripttion:
 * @version:
 * @Author: 张志浩
 * @Date: 2020-11-24 14:20:32
 * @LastEditors: 张志浩
 * @LastEditTime: 2022-01-18 17:29:22
 */
import router from "./router";

import store from "./store";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import { getCookie } from "@/utils/auth"; // get token from cookie

NProgress.configure({ showSpinner: false }); // NProgress Configuration
const whiteList = ["/login"]; // no redirect whitelist 没有白名单

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start();
  if (store.getters.sortable) store.commit("GET_SORT", false);

  var programInfo = "";

  if (getCookie("Auth")) {
    // 判断当前的token是否存在 ； 登录存入的token
    if (to.path === "/login") {
      // if is logged in, redirect to the home page
      if (programInfo.showSwiperMenuFlag === 0) {
        next({ path: "/sign" });
      } else {
        next({ path: "/" });
      }

      NProgress.done();
    } else {
      programInfo = store.getters.programInfo;

      if (programInfo) {
        if (to.meta.keepAlive) {
          console.log(to.name);
          store.commit("SetKeep", to.name);
        }
        next();
      } else {
        programInfo = await store.dispatch("programInfo");
        try {
          var type = [];

          for (var key in programInfo) {
            if (key !== "telephone" && programInfo[key] === 1) {
              type.push(key);
            }
          }

          const accessRoutes = await store.dispatch(
            "permission/generateRoutes",
            type
          );
          router.options.routes = router.options.routes.concat(accessRoutes);
          // // console.log(router.options.routes)
          // // dynamically add accessible routes
          router.addRoutes(accessRoutes);

          // next()
          console.log(to, from);
          if (
            programInfo.showSwiperMenuFlag === 0 &&
            (to.path === "/" ||
              to.path === "/dashboard" ||
              from.path === "/login")
          ) {
            next({ path: "/sign" });
          } else {
            next({ ...to, replace: true });
          }
        } catch (error) {
          // console.log(error)
          store.dispatch("resetToken");
          next(`/login`);
          NProgress.done();
        }
      }
    }
    // if (to.path !== '/concact/index') {
    //   await store.dispatch('getContactList')
    // }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next();
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login`);
    }
    NProgress.done();
  }
});

router.afterEach(() => {
  // finish progress bar
  NProgress.done();
});
