<!--
 * @Descripttion: 
 * @version: 
 * @Author: 张志浩
 * @Date: 2020-08-18 13:45:19
 * @LastEditors: 张志浩
 * @LastEditTime: 2021-09-11 15:08:36
-->
<template>
  <div id="app">
    <div id="preloadedImages" />
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>
<style lang="css">
#preloadedImages {
  width: 0px;
  height: 0px;
  background: url("./assets/login_images/bg.jpg") no-repeat;
}
</style>
