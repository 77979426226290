/*
 * @Descripttion:
 * @version:
 * @Author: 张志浩
 * @Date: 2020-08-18 13:45:19
 * @LastEditors: 张志浩
 * @LastEditTime: 2021-09-11 16:06:11
 */
const state = {
  hrefList: [],
};
const mutations = {
  SET_HREFLIST: (state, hrefList) => {
    state.hrefList = hrefList;
  },
};
export default {
  state,
  mutations,
};
