/*
 * @Descripttion:
 * @version:
 * @Author: 张志浩
 * @Date: 2020-08-18 13:45:19
 * @LastEditors: 张志浩
 * @LastEditTime: 2021-09-11 16:05:43
 */
const state = {
  sortable: false, // 网络异常报错
  sortout: false, // 被顶掉的报错
};
const mutations = {
  GET_SORT: (state, sort) => {
    state.sortable = sort;
  },
  GET_SORTOUT: (state, sort) => {
    state.sortout = sort;
  },
};

export default {
  state,
  mutations,
};
