/*
 * @Descripttion:
 * @version:
 * @Author: 张志浩
 * @Date: 2020-08-18 13:45:19
 * @LastEditors: 张志浩
 * @LastEditTime: 2021-09-11 16:06:58
 */
module.exports = {
  title: "Vue Admin Template",

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: true,
};
