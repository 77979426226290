/*
 * @Descripttion:
 * @version:
 * @Author: 张志浩
 * @Date: 2020-08-18 13:45:19
 * @LastEditors: 张志浩
 * @LastEditTime: 2021-09-11 16:07:21
 */
// import Vue from 'vue'
// import Vuex from 'vuex'
// import getters from './getters'
// import app from './modules/app'
// import settings from './modules/settings'
// import user from './modules/user'
// import sort from './modules/sort'

// Vue.use(Vuex)

// const store = new Vuex.Store({
//   modules: {
//     app,
//     settings,
//     user,
//     sort
//   },
//   getters
// })

// export default store

import Vuex from "vuex";
import Vue from "vue";
// import user from './module/user.js'
// import permission from './module/permission.js'
import getters from "./getters";
Vue.use(Vuex);

const modulesFiles = require.context("./modules", false, /\.js$/);

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, "$1");
  const value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});

const store = new Vuex.Store({
  modules,
  getters,
});

export default store;
