/*
 * @Descripttion:
 * @version:
 * @Author: 张志浩
 * @Date: 2021-09-14 10:02:39
 * @LastEditors: 张志浩
 * @LastEditTime: 2021-09-14 10:26:25
 */
const keepLiveRoute = [];
// ProjectList
const keep = {
  state: {
    keepLiveRoute,
  },

  mutations: {
    ClearKeep(state) {
      if (state.keepLiveRoute.length > 0) state.keepLiveRoute = [];
      console.log("清空缓存路由！");
    },
    SetKeep(state, name) {
      if (!state.keepLiveRoute.includes(name)) {
        state.keepLiveRoute.push(name);
        console.log("设置缓存路由！");
      }
    },
  },

  actions: {},
};

export default keep;
