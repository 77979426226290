/*
 * @Descripttion:
 * @version:
 * @Author: 张志浩
 * @Date: 2020-08-18 13:45:19
 * @LastEditors: 张志浩
 * @LastEditTime: 2021-09-11 16:07:18
 */
const getters = {
  sidebar: (state) => state.app.sidebar,
  device: (state) => state.app.device,
  auth: (state) => state.user.auth,
  unReadCount: (state) => state.user.unReadCount,
  sortable: (state) => state.sort.sortable,
  sortout: (state) => state.sort.sortout,
  programInfo: (state) => state.user.programInfo,
  hrefList: (state) => state.other.hrefList,
};
export default getters;
