/**
 * Created by jiachenpan on 16/11/18.
 */

export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

// export function validUsername(str) {
//   const valid_map = ['admin', 'editor']
//   return valid_map.indexOf(str.trim()) >= 0
// }
export function validUsername(str) {
  if (str === "") return true;
  var regu = /\s/g;
  var re = new RegExp(regu);
  return re.test(str);
}

export function validText(str) {
  if (str === "") return false;
  var regu = /^(?!(\s+$))/;
  var re = new RegExp(regu);
  return re.test(str);
}

export function validURL(url) {
  const reg =
    /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return reg.test(url);
}

export function validLowerCase(str) {
  const reg = /^[a-z]+$/;
  return reg.test(str);
}

export function validUpperCase(str) {
  const reg = /^[A-Z]+$/;
  return reg.test(str);
}

export function validAlphabets(str) {
  const reg = /^[A-Za-z]+$/;
  return reg.test(str);
}

export function isString(str) {
  if (typeof str === "string" || str instanceof String) {
    return true;
  }
  return false;
}

export function isArray(arg) {
  if (typeof Array.isArray === "undefined") {
    return Object.prototype.toString.call(arg) === "[object Array]";
  }
  return Array.isArray(arg);
}

export function isMobile() {
  const flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return flag;
}

// 手机号的正则
export function isPhone(str) {
  var regu = "^((1[3-9]))\\d{9}$";
  var re = new RegExp(regu);
  return re.test(Number(str));
}

export function bfs(tree, ope, filter) {
  const walkAndCopy = (tree, depth = 1) => {
    // //console.log(tree.childs)
    const queue = [];
    // if (filter(tree.status)) {
    const copy = {};
    // ope(tree.status, depth)
    // //console.log(tree)
    if (tree.childs && tree.childs.length > 0) {
      // copy.deptName = tree.deptName
      // copy.parentStatus = tree.parentStatus
      // copy.id = tree.id
      // copy.status = tree.status
      // copy.num = tree.num
      copy.childs = [];
      queue.push({
        nodes: tree.childs,
        depth: depth + 1,
        copyNodes: copy.childs,
      });
    }
    copy.deptName = tree.deptName;
    copy.parentStatus = tree.parentStatus;
    copy.id = tree.id;
    copy.status = tree.status;
    copy.num = tree.num;
    // }
    // //console.log(queue)
    while (queue.length) {
      const item = queue.pop();
      item.nodes &&
        item.nodes.forEach((node) => {
          // //console.log(item)
          if (filter(node.status)) {
            const copyNode = {};
            ope(node.status, item.depth);
            copyNode.deptName = node.deptName;
            copyNode.status = node.status;
            copyNode.id = node.id;
            copyNode.num = node.num ? node.num : "";
            if (node.childs) {
              copyNode.childs = [];
              queue.push({
                nodes: node.childs,
                depth: item.depth + 1,
                copyNodes: copyNode.childs,
              });
            }
            item.copyNodes.push(copyNode);
          }
        });
    }
    // //console.log(filter(copy.parentStatus))
    // 过滤掉整个tree

    // if (filter(-1, copy.parentStatus)) {
    //   copy = ''
    // }
    // //console.log(copy)
    // if (filter(copy.status)) {
    //   // copy = ''
    // }
    return copy;
    // }
  };
  return walkAndCopy(tree);
}
