/*
 * @Descripttion:
 * @version:
 * @Author: 张志浩
 * @Date: 2020-08-18 13:45:19
 * @LastEditors: 张志浩
 * @LastEditTime: 2021-09-11 16:06:34
 */
/**
 * 存储sessionStorage
 */
// export const setStore = (name, content) => {
//   if (!name) return
//   if (typeof content !== 'string') {
//     content = JSON.stringify(content)
//   }
//   window.sessionStorage.setItem(name, content)
// }

// /**
//  * 获取sessionStorage
//  */
// export const getStore = name => {
//   if (!name) return
//   return window.sessionStorage.getItem(name)
// }

// export const removeStore = name => {
//   if (!name) return
//   return window.sessionStorage.removeItem(name)
// }

import Cookies from "js-cookie";

export function getCookie(name) {
  return Cookies.get(name);
}

export function setCookie(name, content) {
  return Cookies.set(name, content);
}

export function removeCookie(name) {
  return Cookies.remove(name);
}
