import Vue from "vue";
import Router from "vue-router";
const Login = () => import("@/pages/login");
const Layout = () => import("@/layout");
const Error401 = () => import("@/pages/errorPage/401");
const Error404 = () => import("@/pages/errorPage/404");
Vue.use(Router);

export const constantRoutes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    hidden: true,
  },
  {
    path: "/404",
    component: Error404,
    hidden: true,
  },
  {
    path: "/500",
    component: Error401,
    hidden: true,
  },

  // {
  //   path: '/concact',
  //   component: Layout,
  //   children: [
  //     {
  //       path: 'index',
  //       name: 'Concact',
  //       component: () => import('@/pages/concact/index'),
  //       meta: { title: '联系列表', icon: require('../assets/concact.png') }
  //     }
  //   ]
  // }
  // {
  //   path: '/project',
  //   component: Layout,
  //   redirect: '/project/area',
  //   name: 'Project',
  //   meta: { title: '投融资服务', icon: require('../assets/project.png'), roles: ['projectShow'] },
  //   children: [
  //     {
  //       path: 'area',
  //       name: 'ProjectArea',
  //       component: () => import('@/pages/project/index'),
  //       meta: { title: '落地类项目', roles: ['projectShow'] }
  //     },
  //     {
  //       path: 'person',
  //       name: 'ProjectPerson',
  //       component: () => import('@/pages/project/projectPer/index'),
  //       meta: { title: '人才类项目', roles: ['projectShow'] }
  //     }
  //   ]
  // },
  // {
  //   path: '/card',
  //   component: Layout,
  //   redirect: '/card/list',
  //   name: 'Card',
  //   children: [
  //     {
  //       path: 'list',
  //       name: 'CardList',
  //       component: () => import('@/pages/card/index'),
  //       meta: { title: '名片管理', icon: require('../assets/concact.png') }
  //     },
  //     {
  //       path: 'carddetail/:cardId',
  //       name: 'CardDetail',
  //       component: () => import('@/pages/card/cardDetail/index'),
  //       meta: { title: '名片详情' },
  //       hidden: true

  //     }
  //   ]
  // },
  // { path: '*', redirect: '/404', hidden: true }
];

export const asyncRoutes = [
  {
    path: "/",
    component: Layout,
    redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: () => import("@/pages/dashboard/index"),
        meta: {
          title: "BANNER设置",
          icon: require("../assets/banner.png"),
          roles: ["showSwiperMenuFlag"],
        },
      },
    ],
  },
  {
    path: "/sign",
    component: Layout,
    redirect: "/sign/list",
    meta: {
      title: "活动管理",
      icon: require("../assets/huodong.png"),
      roles: ["showActivityMenuFlag"],
    },
    children: [
      {
        path: "list",
        name: "Sign",
        component: () => import("@/pages/sign/index"),
        meta: {
          title: "",
          icon: require("../assets/huodong.png"),
          activeMenu: "/sign",
          keepAlive: true,
        },
        hidden: true,
      },
      {
        path: "managesign/:newsId",
        name: "SignManage",
        component: () => import("@/pages/sign/manageSign/index"),
        meta: {
          title: "报名管理",
          noCache: true,
          activeMenu: "/sign",
          keepAlive: true,
        },
        hidden: true,
      },
      {
        path: "addsign",
        name: "SignAdd",
        component: () => import("@/pages/sign/addSign/index"),
        meta: { title: "新增活动", noCache: true, activeMenu: "/sign" },
        hidden: true,
      },
      {
        path: "editSign/:newsId",
        name: "SignEdit",
        component: () => import("@/pages/sign/addSign/index"),
        meta: { title: "编辑活动", noCache: true, activeMenu: "/sign" },
        hidden: true,
      },
    ],
  },
  {
    path: "/news",
    component: Layout,
    redirect: "/news/list",
    meta: {
      title: "新闻设置",
      icon: require("../assets/news.png"),
      roles: ["showNewsMenuFlag"],
    },
    children: [
      {
        path: "list",
        name: "News",
        component: () => import("@/pages/news/index"),
        meta: {
          title: "",
          icon: require("../assets/news.png"),
          activeMenu: "/news",
          keepAlive: true,
        },
        hidden: true,
      },
      {
        path: "edit/:newsId",
        name: "NewsEdit",
        component: () => import("@/pages/news/newsdetail/index"),
        meta: { title: "编辑新闻", noCache: true, activeMenu: "/news" },
        hidden: true,
      },
      {
        path: "add",
        name: "NewsAdd",
        component: () => import("@/pages/news/newsdetail/index"),
        meta: { title: "新增新闻", noCache: true, activeMenu: "/news" },
        hidden: true,
      },
    ],
  },
  {
    path: "/carrier",
    component: Layout,
    redirect: "/carrier/list",
    meta: {
      title: "载体管理",
      icon: require("../assets/zaiti.png"),
      roles: ["showCarrierMenuFlag"],
    },
    children: [
      {
        path: "list",
        name: "Carrier",
        component: () => import("@/pages/carrier/index"),
        meta: {
          title: "",
          icon: require("../assets/news.png"),
          activeMenu: "/carrier",
          keepAlive: true,
        },
        hidden: true,
      },
      {
        path: "managesign/:newsId",
        name: "CarrierManage",
        component: () => import("@/pages/carrier/manageCarrier/index"),
        meta: {
          title: "载体报名管理",
          noCache: true,
          activeMenu: "/carrier",
          keepAlive: true,
        },
        hidden: true,
      },
      {
        path: "addcarrier",
        name: "CarrierAdd",
        component: () => import("@/pages/carrier/addCarrier/index"),
        meta: { title: "新增载体", noCache: true, activeMenu: "/carrier" },
        hidden: true,
      },
      {
        path: "editCarrier/:newsId",
        name: "CarrierEdit",
        component: () => import("@/pages/carrier/addCarrier/index"),
        meta: { title: "编辑载体", noCache: true, activeMenu: "/carrier" },
        hidden: true,
      },
    ],
  },
  {
    path: "/project",
    component: Layout,
    redirect: "/project/area",
    name: "Project",
    meta: {
      title: "项目管理",
      icon: require("../assets/project.png"),
      roles: ["showProjectMenuFlag"],
    },
    children: [
      {
        path: "area",
        name: "ProjectArea",
        component: () => import("@/pages/project/index"),
        meta: { title: "落地类项目", keepAlive: true },
      },
      {
        path: "person",
        name: "ProjectPerson",
        component: () => import("@/pages/project/projectPer/index"),
        meta: { title: "人才类项目", keepAlive: true },
      },
    ],
  },
  //镇江特供项目上传
  {
    path: "/project",
    component: Layout,
    redirect: "/project/areaList",
    name: "ProjectList",
    meta: {
      title: "项目管理",
      icon: require("../assets/project.png"),
      roles: ["d6ef2f745167bf03481a413dbc628c3e"],
    },
    children: [
      {
        path: "areaList",
        name: "ProjectAreaList",
        component: () =>
          import("@/pages/specialVersionForArea/projectForZhenjiang/index"),
        meta: { title: "落地类项目", keepAlive: true },
      },
      {
        path: "personList",
        name: "ProjectPersonList",
        component: () =>
          import(
            "@/pages/specialVersionForArea/projectForZhenjiang/projectPer/index"
          ),
        meta: { title: "人才类项目", keepAlive: true },
      },
    ],
  },
  {
    path: "/card",
    component: Layout,
    redirect: "/card/list",
    name: "Card",
    meta: {
      roles: ["showCardMenuFlag"],
      title: "名片管理",
      icon: require("../assets/card.png"),
    },
    children: [
      {
        path: "list",
        name: "CardList",
        component: () => import("@/pages/card/index"),
        meta: {
          title: "",
          icon: require("../assets/card.png"),
          activeMenu: "/card",
          keepAlive: true,
        },
        hidden: true,
      },
      {
        path: "carddetail/:cardId",
        name: "CardDetail",
        component: () => import("@/pages/card/cardDetail/index"),
        meta: { title: "名片详情", activeMenu: "/card" },
        hidden: true,
      },
    ],
  },
  {
    path: "/concact",
    component: Layout,
    children: [
      {
        path: "index",
        name: "Concact",
        component: () => import("@/pages/concact/index"),
        meta: {
          title: "联系列表",
          icon: require("../assets/concact.png"),
          roles: ["showContactMenuFlag"],
          keepAlive: true,
        },
      },
    ],
  },
  {
    path: "/demand",
    component: Layout,
    redirect: "/demand/list",
    name: "Demand",
    meta: {
      title: "需求列表",
      icon: require("../assets/demand.png"),
      roles: ["showDemandMenuFlag"],
    },
    children: [
      {
        path: "list",
        name: "DemandList",
        component: () => import("@/pages/demand/index"),
        meta: {
          title: "",
          icon: require("../assets/demand.png"),
          activeMenu: "/demand",
          keepAlive: true,
        },
        hidden: true,
      },
    ],
  },
  {
    path: "/member",
    component: Layout,
    redirect: "/member/list",
    name: "Member",
    meta: {
      title: "入会申请",
      icon: require("../assets/member.png"),
      roles: ["showMemberMenuFlag"],
    },
    children: [
      {
        path: "list",
        name: "DemandList",
        component: () => import("@/pages/member/index"),
        meta: {
          title: "",
          icon: require("../assets/member.png"),
          activeMenu: "/member",
          keepAlive: true,
        },
        hidden: true,
      },
    ],
  },
  {
    path: "/applycard",
    component: Layout,
    redirect: "/applycard/list",
    name: "Applycard",
    meta: {
      title: "授权名片",
      icon: require("../assets/applyCard.png"),
      roles: ["showApplyCardMenuFlag"],
    },
    children: [
      {
        path: "list",
        name: "ApplycardList",
        component: () => import("@/pages/applyCard/index"),
        meta: {
          title: "",
          activeMenu: "/applycard",
          keepAlive: true,
        },
        hidden: true,
      },
    ],
  },
  { path: "*", redirect: "/404", hidden: true },
];

const createRouter = () =>
  new Router({
    mode: "history", // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
// export function resetRouter() {
//   const newRouter = createRouter()
//   router.matcher = newRouter.matcher // reset router
// }

export default router;
